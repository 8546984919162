@import '../../../static/assets/css/variables';

// $size-base is 8px at the moment.
$pixel0: 0;
$pixel4: $size-base / 2;
$pixel12: $pixel4 * 3;
$pixel16: $pixel4 * 4;
$pixel32: $pixel4 * 8;

.enter-code {
  .auth-enter-code {
    padding: $pixel0;
    width: auto;
    background-color: transparent;
    box-shadow: none;
    border-radius: none;

    header.modal-header {
      text-align: left;
      display: initial;
      align-items: initial;
      flex-direction: initial;

      .sub-header {
        padding: $pixel16 $pixel0 ($pixel32 + 1);
      }

      h1.ux-page-header {
        padding-left: initial;
        padding-top: initial;
        justify-content: center;
        display: flex;
      }

      p.ux-standard-paragraphs {
        padding-left: initial;
        padding-bottom: initial;
      }
    }

    main {
      text-align: left;
      display: initial;
      align-items: initial;
      flex-direction: initial;
      padding-bottom: $pixel0;

      .code-input-container {
        padding-top: $pixel0;
        padding-bottom: $pixel0;
        display: flex;
        width: 100%;
        justify-content: center;

        .input {
          margin-top: ($pixel32 - 3);
          margin-bottom: 0;

          .idm-input {
            input {
              margin-top: 0;
            }
          }
        }
      }
    }

    footer {
      margin-top: $pixel4 * 14;
    }
  }

  @media all and (max-width: $break-small) {
    .auth-enter-code {
      main {
        button[data-meta-id='choose-method-responsive'] {
          display: block;
          margin-top: $pixel4 * 5;
        }
      }

      footer {
        button[data-meta-id='choose-method'] {
          visibility: hidden;
          width: 0;
        }
      }
    }
  }
}
