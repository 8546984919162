@import '../../../static/assets/css/variables';

// layout stuff
.loader {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}

.change-username {
  header {
    text-align: center;

    .ux-page-header {
      padding-left: 0;
    }

    .ux-standard-paragraphs {
      padding-left: 0;

      span {
        font-size: $font-size-xs;
      }
    }
  }

  main {
    .method-listing {
      padding-bottom: 10px;

      .authentication-method-display span {
        font-size: $font-size-md;
      }
    }
  }

  footer {
    display: flex;
    justify-content: flex-end;
  }

  .buttonRow {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 40px;
  }

  .educational-message-text {
    text-align: center;
    font-size: 14px !important;
    font-weight: 450;
  }

  .review-username-message-text {
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 30px;
  }

  p {
    text-align: justify;
  }

  footer {
    div {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    button {
      margin-right: 3%;
    }
  }

  form {
    button {
      margin-right: 3%;
    }
  }

  .usernameInput {
    text-align: left;
    display: block;
  }

  .usernameBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .lileft {
    justify-content: left;
    min-width: fit-content;
  }

  .usernameSection {
    text-align: left;
    padding: 5px;
  }

  .labelText {
    display: flex;
    font-size: 13px;
    padding-bottom: 15px;
  }

  .suggestedUserId {
    display: flex;
    font-size: 13px;
    inline-size: 30px;
    color: #212125;
    word-wrap: break-word;
    padding-bottom: 15px;
    margin-left: 1px;
  }

  .clientMainSubtitle {
    font-size: 16px;
    font-weight: $font-weight-bold;
    color: #212125;
    padding-top: 25px;
    padding-bottom: 15px;
  }

  .clientNameTitle {
    font-size: 14px;
    font-weight: 600;
    color: #212125;
    padding-bottom: 15px;
  }

  .suggestedIdLabel {
    font-size: 13px;
    font-weight: $font-weight-bold;
    color: #76767A;
  }

  .input-label {
    font-size: 13px;
    font-weight: 500;
    color: #212125;
  }

  .copyButton {
    display: flex;
    justify-content: left;
    padding-left: 20px;
    width: 80%;
    max-height: 30px;
  }

  .selectText {
    color: #3150A5;
    cursor: pointer;
    font-weight: 500;
    font-size: 13px;
  }

  .selectText:hover {
    text-decoration: underline;
  }

  .darkLine {
    width: 100%;
    border: 0.5px solid #E3DFDB;
    margin: 0 24px 0 0;
    height: 0.5px;
    margin-bottom: 30px;
  }

  .titleHeader {
    padding-top: 1px;
    padding-bottom: 20px;
  }

  .continueButton {
    padding-bottom: 1px;
  }

  .systemGenUsernameInput {
    text-align: left;
    display: block;
  }

  .clientHeader {
    margin-bottom: 20px;
  }

  .clientName {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
  }

  .fieldLabel {
    font-size: 13px;
    font-weight: 500;
    color: #212125;
    padding-bottom: 5px;
  }

  .fieldValue {
    font-size: 13px;
    font-weight: 400;
    color: #212125;
    padding-bottom: 15px;
  }

  .warningMessage {
    display: flex;
    align-items: center;
    padding: 20px;
    margin-top: 20px;
    background-color: #FFF4E5;
    border-radius: 5px;
    color: #212125;
    font-size: 14px;
    margin-bottom: 30px;
  }

  .warningIcon {
    margin-right: 10px;
    font-size: 18px;
    width: 20px;
    height: 15px;
    margin-bottom: 15px;
  }

  .warningText {
    font-weight: 200;
  }

  .warningText strong {
    font-weight: 500;
  }
}

@media all and (max-width: $break-large) {
  .change-username {
    .titleHeader {
      padding-top: 80px;
      padding-bottom: 20px;
    }

    .buttonRow {
      padding-bottom: 200px;
    }

    .continueButton {
      padding-bottom: 200px;
    }
  }
}
