@import '../../../static/assets/css/variables';

$pixel0: 0;
$pixel4: $size-base / 2;
$pixel12: $pixel4 * 3;
$pixel16: $pixel4 * 4;
$pixel32: $pixel4 * 8;

.add-phone {
  .auth-enter-code {
    padding: $pixel0;
    width: auto;
    background-color: transparent;
    box-shadow: none;
    border-radius: none;

    header.modal-header {
      text-align: center;
      justify-content: center;
      display: initial;
      align-items: initial;
      flex-direction: initial;

      .sub-header {
        padding: $pixel16 $pixel0 ($pixel32 + 1);
      }

      h1.ux-page-header {
        padding-left: initial;
        padding-top: initial;
        justify-content: center;
        display: flex;
      }

      p.ux-standard-paragraphs {
        padding-left: initial;
        padding-bottom: initial;
      }
    }

    main {
      text-align: left;
      display: initial;
      align-items: initial;
      flex-direction: initial;
      padding-bottom: $pixel0;

      .code-input-container {
        padding-top: $pixel0;
        padding-bottom: $pixel0;

        .input {
          margin-top: ($pixel32 - 3);
          margin-bottom: $pixel0;

          .idm-input {
            input {
              margin-top: $pixel0;
            }
          }
        }
      }
    }

    footer {
      justify-content: center;
      margin-top: (3 * $pixel16) +  $size-base;
    }
  }

  .idm-dropdown {
    margin: (2 * $pixel12) auto $pixel0;
  }

  .idm-dropdown-box {
    padding-top: $pixel4;
  }

  .ant-select-selection {
    height: $pixel32;
  }

  .button-container {
    float: right;
    margin-top: $pixel16 + $pixel4;

    .button-input {
      width: (6 * $pixel12) + 2;
    }
  }

  .ux-small-paragraphs {
    line-height: $pixel16 + $pixel4;
  }
}
