@import '../../../static/assets/css/keyframes';
@import '../../../static/assets/css/variables';

.toast-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  max-width: 620px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: $border-radius-default;
  background-color: $white;
  border: 1px solid $grey-400;
  animation: 500ms ease-in-out 0s 1 fadeIn forwards, 500ms ease-in-out 4s 1 fadeIn reverse forwards;
  transition: top ease-in-out 1s;

  .toast-icon-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 32px;
    height: 100%;
    background-color: $grey-400;

    svg {
      fill: $white;
      color: $white;
    }
  }

  .toast-message-holder {
    font-size: $font-size-base;
    line-height: $line-height-base;
    text-align: center;
    padding: $size-base*4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.error {
    border: 1px solid $red-300;

    .toast-icon-holder {
      background-color: $red-300;
    }

    .toast-message-holder {
      color: $red-600;
    }
  }

  &.success {
    border: 1px solid $green-300;

    .toast-icon-holder {
      background-color: $green-300;
    }

    .toast-message-holder {
      color: $green-500;
    }
  }

  &.info {
    border: 1px solid $amber-300;

    .toast-icon-holder {
      background-color: $amber-300;
    }

    .toast-message-holder {
      color: $amber-600;
    }
  }
}
