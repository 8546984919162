@import 'theirs';
@import 'shared';

a:active,
a:hover,
a:focus {
  text-decoration: underline;
}

p.ux-standard-paragraphs,
p.ux-standard-paragraphs > p {
  color: $font-color-black;
}
