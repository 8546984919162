@import '../../../static/assets/css/variables';

.legal-doc {
  .font-bold {
    font-weight: $font-weight-bold;
  }

  p {
    margin-top: $line-height-md;
  }

  i.fa {
    padding-right: $size-base;
  }

  .header {
    margin-top: 20px;
  }
}
