@import '../../../static/assets/css/variables';

.user-confirmation {
  margin: 0 auto;
  width: 100%;
  max-width: 480px;
  border-radius: $border-radius-default;
  background-color: $white;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
  z-index: 1;

  .confirmation-header {
    padding: $size-base*3 $size-base*3 17px;
    font-size: $font-size-md;
    line-height: $line-height-lg;
    border-bottom: 1px solid $grey-200;
    color: $grey-700;
  }

  .identity {
    padding: $size-base*3;
    display: flex;
    text-decoration: none;
    transition: background-color 0.1s ease-out;
    outline: none;

    .avatar {
      width: 64px;
      height: 64px;
    }

    &:hover {
      background-color: $blue-50;
    }

    .details {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      margin-left: $size-base*2;
      margin-right: $size-base*3;
      color: $grey-700;
      font-size: $font-size-lg;

      .primary-text {
        line-height: $line-height-lg;

        .name {
          font-weight: $font-weight-bold;
        }
      }

      .secondary-text {
        color: $grey-500;
        font-size: $font-size-sm;
        line-height: $line-height-md;
      }
    }

    i {
      display: flex;
      align-items: center;
      color: $grey-200;
      font-size: $font-size-xl;
      line-height: $line-height-lg;
      transition: color 0.1s ease-out;
    }

    &:hover i {
      color: $blue-400;
    }
  }
}
