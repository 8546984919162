@import '~/src/lib/static/assets/css/ux_2.0';

.headerContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: $bds-c-global-nav-max-width;
  background-color: $bds-c-global-nav-background-color;
  display: flex;
  justify-content: center;
}

.headerContent {
  width: 100%;
  height: $bds-c-global-nav-height;
  padding: $bds-c-global-nav-padding;
  color: $bds-c-global-nav-brand-client-font-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lifionLogoContainer {
  fill: $bds-c-global-nav-brand-client-font-color;
  margin: $bds-c-global-nav-actions-item-margin;
}

.lifionLogo {
  width: $bds-c-global-nav-brand-logo-icon-size;
}

.rightSection {
  display: flex;
  align-items: center;
}

button.backButton {
  display: flex;
  align-items: center;
  font-weight: $bds-c-global-nav-dropdown-profile-preview-title-font-weight;
  color: $bds-c-global-nav-brand-client-font-color;
}

button.backButton:hover {
  color: $bds-c-global-nav-brand-client-font-color;
  text-decoration: underline;
}

.backArrow {
  height: 1.5em;
  width: 1.5em;
}

.userInfo {
  display: flex;
  align-items: center;
  margin: $bds-c-global-nav-actions-item-margin;
  width: $bds-c-global-nav-actions-container-width;
}

div.avatar {
  height: $bds-c-global-nav-dropdown-item-icon-height;
  width: $bds-c-global-nav-dropdown-item-icon-width;
  font-size: $bds-c-global-nav-dropdown-profile-preview-title-font-size;
  font-weight: $bds-c-global-nav-dropdown-profile-preview-title-font-weight;
  margin: $bds-c-global-nav-actions-item-margin;
}

.details {
  display: flex;
  flex-direction: column;
}

.name {
  color: $bds-c-global-nav-dropdown-profile-preview-font-color;
  font-size: $bds-c-global-nav-dropdown-profile-preview-title-font-size;
  font-weight: $bds-c-global-nav-dropdown-profile-preview-title-font-weight;
  line-height: $bds-c-global-nav-dropdown-profile-preview-title-font-line-height;
}

button.logoutButton {
  color: $bds-c-global-nav-dropdown-profile-preview-font-color;
  font-size: $bds-c-global-nav-dropdown-profile-preview-title-font-size;
  font-weight: $bds-c-global-nav-dropdown-profile-preview-name-font-weight;
  line-height: $bds-c-global-nav-dropdown-profile-preview-title-font-line-height;
  padding: 0;
}

button.logoutButton:hover {
  color: $bds-c-global-nav-dropdown-profile-preview-font-color;
  text-decoration: underline;
}
