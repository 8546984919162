
.buttonContainer {
  padding-top: 5px;
  padding-left: 10px;
}

.settingsForm {
  max-width: 432px;
}

.dropdown {
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 8px;
}

.regionText {
  padding-bottom: 0;
}
