.change-password-form {
  padding: 8px;
}

.change-password-form .button-row {
  padding-top: 20px;
  justify-content: flex-start;
}

.button-row {
  display: flex;
  justify-content: center;
}
