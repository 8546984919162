@import '../../../static/assets/css/variables';

.idm-messages {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $size-base*2 $size-base*4;
  pointer-events: none;

  .toast-message {
    margin: 0 0 $size-base;
    pointer-events: all;
  }

  .toast-message:last-child {
    margin: 0;
  }
}
