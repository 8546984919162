@import '../../../../static/assets/css/variables';

.idm-devices-tab {
  font-size: $font-size-sm;

  .info-text {
    color: $grey-500;
    font-size: $font-size-base;
    line-height: $line-height-md;
    padding-bottom: $size-base*2;
  }

  .idm-button-input.link {
    margin: 0;

    input {
      margin: 0;
      float: none;
      padding: 0;
      font-size: $font-size-xs;
      font-weight: normal;
      height: initial;
      line-height: inherit;
    }
  }

  .devices-table {
    td,
    th {
      padding: $size-base*2;
      font-size: $font-size-base;
      line-height: $size-base*3;
      word-break: break-word;
    }

    .not-configured {
      color: $grey-500;
    }

    .ant-table-thead > tr > th {
      font-weight: $font-weight-bold;
      color: $font-color-medium;
      background-color: $grey-50;
    }

    .ant-table-tbody > tr {
      color: $font-color-light;

      &:hover > td {
        background-color: transparent;
      }
    }

    .ant-table-placeholder {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-content: center;
      height: 145px;
      font-style: italic;
      justify-content: center;
    }

    .ant-pagination {
      display: flex;
      align-items: center;
      font-size: $font-size-base;

      .ant-pagination-prev {
        margin-left: $size-base;
      }

      .ant-pagination-prev,
      .ant-pagination-next {
        background-color: $grey-150;
        height: 24px;
        min-width: 24px;
        line-height: $line-height-lg;
      }

      .ant-pagination-jump-prev,
      .ant-pagination-jump-next {
        min-width: initial;

        &:after {
          content: '';
        }
      }

      .ant-pagination-prev:not(.ant-pagination-disabled):hover,
      .ant-pagination-next:not(.ant-pagination-disabled):hover {
        color: $grey-700;
        background-color: $grey-300;
      }

      .ant-pagination-disabled {
        background-color: $grey-50;
        color: $grey-400;
      }

      .ant-pagination-item {
        background-color: transparent;
        border: none;
        color: $grey-500;
        border-radius: 0;
        min-width: initial;
        padding: 0 4px;
        height: $size-base*3;
        line-height: $line-height-lg;
        transition: none;

        &:hover {
          color: $grey-600;
          border-bottom: 2px solid $blue-200;
        }

        &.ant-pagination-item-active {
          color: $blue-400;
          border-bottom: 2px solid $blue-400;
        }
      }
    }

    .device-row-authentication-revoked td {
      color: $grey-400;
    }
  }
}
