.contact-info-view .method-listing {
  padding-bottom: 10px;
}

.contact-info-view .add-contact-button {
  float: none !important; //important is used because ow this has the same level of specificity as in account settings
  margin: 0;
}

.contact-info-view .add-contact-button input {
  float: none;
  margin: 0;
}
