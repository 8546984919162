@import '../../../static/assets/css/variables';

.inline-message {
  display: flex;
  align-items: center;
  line-height: $line-height-md;
  font-size: $font-size-base;
  border-radius: $border-radius-default;
  padding: $size-base*2;
  color: $grey-600;
  background-color: $grey-50;
  border: 1px solid $grey-400;

  ul {
    padding-left: 17px;

    li {
      list-style: none;
    }
  }

  &.error-message {
    color: $red-600;
    background-color: $red-50;
    border: 1px solid $red-400;

    svg {
      fill: $red-400;
      margin-right: $size-base*2;
    }

    a {
      color: $red-600;
    }
  }
}
