@import '../../../static/assets/css/variables';

.impersonation-modal-body {
  padding-bottom: $size-base * 3;

  .info-container {
    .info-container-body {
      margin-top: $size-base*2;
      border: 1px solid $grey-200;
      border-radius: $size-base/2;
      padding: $size-base*2;

      .section {
        margin-bottom: $size-base/2;

        .user-name-text {
          font-size: $font-size-lg;
          font-weight: $font-weight-bold;
        }

        .client-name-text {
          font-weight: $font-weight-semibold;
        }
      }
    }
  }

  .info-header-text,
  .confirmation-header-text {
    font-size: $font-size-md;
    font-weight: $font-weight-bold;
  }

  .modal-body-container-margin {
    margin-bottom: $size-base*4;

    span {
      font-size: $font-size-md;
      line-height: $line-height-lg;
      color: $grey-700;
    }

    .impersonate-form {
      padding-top: $size-base * 2;

      .impersonate-form-fields {
        .form-field-label {
          padding-bottom: $size-base / 2;

          .form-field-label-text {
            padding-right: $size-base;
            color: $grey-600;
            font-size: $font-size-base;
            font-weight: $font-weight-normal;
          }
        }

        .ux-validated-input {
          .input-wrap {
            .ux-text-input {
              padding: 0;
            }
          }
        }

        input::placeholder {
          color: $grey-400 !important;
        }
      }
    }
  }

  .modal-body-container-margin:last-child {
    margin-bottom: 0;
  }

  .data-loader {
    z-index: 10;
    background: $background-color-base;
  }
}
