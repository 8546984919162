@import '../../../static/assets/css/variables';

.leftPane {
  color: $white;
}

.content {
  position: relative;
  top: 10%;
  left: 15%;
  pointer-events: none;
}

.logo {
  fill: $white;
  width: 60px;
}

h1.mainTitle,
h2.subtitle {
  max-width: 400px;
  color: $white;
  padding: 8px 0;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
  width: 100%;
  font-family: Circular, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
}

h1.mainTitle {
  font-size: 64px;
  line-height: 64px;
  padding-top: 48px;
}

h2.subtitle {
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
}

.divider {
  height: 8px;
  width: 128px;
  background: $red-200;
  margin: 24px 0;
  border-radius: 4px;
}

@media all and (max-width: $break-large) {
  .leftPane {
    padding-bottom: 8px;
  }

  .content {
    top: 0;
    left: 0;
    padding: 64px 128px;
    padding-top: 72;
    padding-bottom: 0;
  }

  .logo {
    fill: initial;
  }

  .divider {
    height: 0;
    width: 0;
    margin: 12px 0;
  }

  h1.mainTitle {
    font-size: 48px;
    line-height: 48px;
  }

  h2.subtitle {
    font-size: 16px;
    line-height: 24px;
  }

  h1.mainTitle,
  h2.subtitle {
    color: initial;
    text-shadow: none;
    max-width: 100%;
  }
}

@media all and (max-width: $break-small) {
  .description { display: none; }

  .content {
    padding: 72px 0 48px 40px;
  }

  .divider {
    display: none;
  }
}
