@import '../../../../../../../../static/assets/css/variables';

.authentication-method-display {
  display: flex;
  align-items: center;
}

.type-label {
  display: block;
}

.authentication-method-display .icon {
  padding: 20px 20px 20px 0;
  min-width: 60px;
  color: $blue-400;
}
