@import '~@lifion/ds-foundations-latest/dist/2.0/web-platform/web-tokens';

.validationBox {
  max-width: 704px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  padding: 8px 0 8px 12px;
  border-width: $ds-c-section-border-width;
  border-style: solid;
  border-color: $ds-c-section-border-color;
  border-radius: $ds-c-section-border-radius;
}

.rulesMet {
  display: flex;
  flex-wrap: wrap;
  padding-inline-end: 5vw;
  width: 100%;
}

.icon {
  display: flex;
  justify-content: center;
  width: 30px;
}

p.ruleName,
p.icon {
  padding: 8px;
}

.validationBox p {
  font-size: $ds-u-typography-paragraph-s-size;
  font-weight: $ds-u-typography-paragraph-s-weight;
  line-height: $ds-u-typography-paragraph-s-line-height;
  letter-spacing: $ds-u-typography-paragraph-s-letter-spacing;
  color: $ds-u-text-color-neutral-light;
}

.validationBoxHeaderWrapper {
  padding-bottom: 10px;
}

.validationBoxHeader {
  font-size: $ds-u-typography-header-s-size;
  font-weight: $ds-u-typography-header-s-weight;
  line-height: $ds-u-typography-header-s-line-height;
  letter-spacing: $ds-u-typography-header-s-letter-spacing;
  color: $ds-u-typography-header-s-color;
}

.iconSpanWrapper {
  height: 24px;
}

.rule {
  min-width: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  height: 40px;
}

.successIcon {
  color: green;
}

.showLink {
  position: absolute;
  right: 15px;
  bottom: 0;
  cursor: pointer;
  user-select: none;
  font-size: $ds-u-typography-paragraph-s-size !important;
  font-weight: calc($ds-u-typography-paragraph-s-weight - 50) !important;
  line-height: $ds-u-typography-paragraph-s-line-height !important;
  letter-spacing: $ds-u-typography-header-s-letter-spacing !important;
  color: $ds-u-text-color-neutral-light !important;
}

.passwordInput > section {
  position: relative;
}

// Allows for styling "Update Password" input headers specifically
.passwordInput > label > div {
  font-size: $ds-u-typography-text-m-size;
  font-weight: $ds-u-typography-text-m-weight;
  line-height: $ds-u-typography-text-m-line-height;
  letter-spacing: $ds-u-typography-header-s-letter-spacing;
  color: $ds-u-typography-text-m-color;
  padding-bottom: 8px;
}

.passwordContainer {
  padding-top: 8px;
  padding-bottom: 16px;
}

.inputContainer {
  height: 84px;
  margin-bottom: 12px;
}

input[type='password']:not(:placeholder-shown) {
  font-size: 13px;
  letter-spacing: 2px;
  font-family: caption;
  color: $ds-u-typography-text-m-color;
}
