@import '../../../../static/assets/css/variables';

.recaptcha-error-message {
  min-height: $line-height-base + 2 * $size-base;
  padding-left: $size-base;
}

.recaptcha-container {
  margin-top: $line-height-base + 2 * $size-base;
}
