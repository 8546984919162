@import '~@lifion/ds-foundations-latest/dist/2.0/web-platform/web-tokens';
@import '../../../static/assets/css/variables';

.tab {
  margin-bottom: -4px; // Make the list-items overlay the bottom border
  float: none;
  vertical-align: bottom;

  // Actual tabs (links)
  a {
    position: relative;
    display: inline-block;
    max-width: 15em;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: $ds-c-tab-active-font-weight;
    background-color: transparent;
    border: 0;
    color: $ds-c-tab-color;
    font-size: $ds-c-tab-font-size;
    line-height: $ds-c-tab-line-height;

    &:hover {
      color: $font-color-medium;

      &:after {
        transform: scale(1);
        background-color: $border-color-hover;
        height: 4px;
        border-radius: 10px;
      }
    }

    &:after {
      transition: transform 0.1s linear;
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      margin-top: 8px;
      background-color: $blue-300;
      transform: scale(0);
    }
  }

  a,
  a:focus,
  a:hover {
    text-decoration: none;
  }

  display: inline-block;
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
  }

  // Active state, and its hover state will override normal hover state
  a.active {
    color: $ds-c-tab-active-color;
    pointer-events: none;

    &:after {
      background-color: $ds-c-button-primary-border-color;
      transform: scale(1);
      height: 4px;
      border-radius: 10px;
    }
  }
}
