/* eslint-disable */
@import '../../../../static/assets/css/keyframes';
@import '../../../../static/assets/css/variables';

.idmBackgroundPage {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  position: relative;
  width: 100vw;
  max-height: max-content;
  padding-top: 10vh;
  padding-bottom: 20vh;
}

.background {
  display: none;
  overflow: hidden;
}

.mainContent {
  display: flex;
  background: $white;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 0 1%;
  border-radius: 10px;
  overflow: visible;
}

.linktext {
  font-size: 16px;
  font-family: 'Proxima Nova', sans-serif;
  font-weight: 400;
  color: #324FA5;
}

html {
  overflow-x: hidden;
}

.body {
  flex-grow: 1;
  padding: 30px 20px;
  width: 100%;
  align-self: center;
  align-items: center;
}

.vdlfooter {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #E3DFD6;
}

.ulc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin: 0;
  flex-wrap: wrap;
}

.lileft {
  list-style: none;
  flex: 1;
  flex: 0 0 6.5%;
}

.copyright {
  list-style: none;
  margin: 0;
  color: #262321;
  font-size: 16px;
  font-family: 'Proxima Nova', sans-serif;
  font-weight: 400;
  text-align: right;
  flex: 1;
  width: 80%;
}

.vdlbutton {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vdlbuttonlink {
  text-decoration: none;
  color: #324FA5;
  font-size: 14px;
  font-family: 'Proxima Nova', sans-serif;
  font-weight: 300;
  align-items: center;
  justify-content: center;
}

.vdlbuttoncontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
}

.svgcontainer {
  margin-right: 8px;
}

.center_a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media all and (max-width: $break-large) {
  .idmBackgroundPage {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 3vh;
  }

  .mainContent {
    width: 100%;
    border-radius: 10px;
    align-self: center;
    margin: 0 auto;
  }

  .body {
    padding: 5px 20px;
    width: 95%;
    font-size: 16px;
    margin: 0 auto;
  }

  .ulc {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #CCCCCC;
    padding: 10px;
  }

  .lileft {
    list-style: none;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #CCCCCC;
    padding-bottom: 3px;
  }

  .copyright {
    list-style: none;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-bottom: none;
  }

  .vdlbuttonlink {
    align-items: center;
    justify-content: center;
  }

  .center_a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

@media all and (max-width: $break-small) {
  .idmBackgroundPage {
    padding-bottom: 10vh;
  }

  .mainContent {
    padding: 0 $size-base*2;
  }

  .body {
    padding: 0 $size-base*1;
    font-size: 16px;
    width: 95%;
  }

  .vdlfooter {
    justify-content: center;
    position: relative !important;
    align-items: center;
    left: 0;
    right: 0;
  }

  .ulc {
    flex-direction: column;
    align-items: center;
  }

  .lileft .copyright {
    list-style: none;
    margin: 5px 0;
    text-align: center;
    align-items: center;
  }

  .vdlbuttonlink {
    align-items: center;
    justify-content: center;
  }

  .center_a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

@media all and (min-width: $break-large + 1) {
  .background {
    background-image: url('../../../../static/assets/images/unauthenticated/ADP_bg.webp');
    width: 100vw;
    height: 100%;
    position: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    background-position: center;
  }

  .mainContent {
    min-width: 400px;
    width: 100%;
    max-width: 560px;
    overflow-wrap: break-word;
    top: 0;
  }

  .vdlfooter {
    display: inline-block;
    flex-direction: row;
    position: fixed;
    bottom: 0;
    align-items: flex-start;
    border-top: 1px solid #CCCCCC;
    background: #E3DFD6;
  }

  .ulc {
    flex-direction: row;
    align-items: flex-start;
  }

  .lileft .copyright {
    list-style: none;
    margin: 5px 0;
    font-family: 'Proxima Nova', sans-serif;
  }

  .center_a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
