@import '../../../../../static/assets/css/variables';
@import '../../../../../static/assets/css/extensions';

.results-content {
  padding: $size-base * 0.5;
  box-shadow: none;
  overflow-x: scroll;

  .search-results {
    margin-top: $size-base * 2;
  }

  .search-header {
    padding-left: 0;
  }

  .data-loader {
    z-index: 10;
  }

  .search-bar-container {
    .search-flex {
      padding: 0;
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: left;
      align-items: center;
    }
  }
}

.tab-view {
  @extend %white-paper;

  margin-bottom: $size-base * 1;
  height: 950px;
  overflow: auto;
}

.search-bar {
  margin-right: $size-base * 2;
}

.home-page-tabs {
  .ant-tabs-tab {
    margin: 0 !important;
    padding: $size-base 2 * $size-base !important;
  }
}

.fixed-navigator {
  display: block;
  overflow: auto;
  white-space: nowrap;

  .ant-spin-container {
    overflow: auto;

    ul:last-child {
      background-color: white;
      position: sticky;
      left: $size-base * 166;
      align-self: flex-end;
    }
  }
}

.idm-input .add-on-icon {
  background-color: white;
}

.idm-modal .ant-modal-header .ant-modal-title {
  font-family: sans-serif !important;
}

.ant-radio-wrapper {
  display: none;
}
