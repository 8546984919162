@import '../../../static/assets/css/variables';

.outage-page-container {
  max-width: 1328px;
  margin: 100px auto;
  padding: $size-base*10 $size-base*3;

  @media (max-width: $break-large) {
    max-width: 70%;
    margin-top: 0;
  }

  @media (max-width: $break-small) {
    max-width: 100%;
  }

  .outage-page-layout {
    flex-direction: row;
    align-items: center;

    @media (max-width: $break-large) {
      flex-direction: column-reverse;
    }

    .message-container {
      flex-direction: column;
      align-items: flex-start;

      .lifion-logo {
        height: 42px;
        margin-bottom: $size-base*8;
        margin-left: $size-base;
      }

      .xlarge-page-header {
        font-size: $font-size-jumbo;
        font-weight: $font-weight-bold;
        line-height: $line-height-jumbo;
        margin: $size-base*0.5 $size-base;
      }

      .message {
        font-size: $font-size-md;
        font-weight: $font-weight-normal;
        line-height: $line-height-lg;
        color: $grey-500;
        margin: $size-base*0.5 $size-base;
      }
    }

    .chair-with-spill {
      flex: 0 0 58%;

      img {
        width: 100%;
      }
    }
  }
}
