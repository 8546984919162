@import '../../../static/assets/css/variables';

.titledIconContainer {
  display: flex;
  align-items: center;
}

.titledIconText {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.iconCircle {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  fill: $white;
  width: 100%;
  height: 100%;
}

.iconCircle.error {
  background-color: $red-300;
}

.iconCircle.success {
  background-color: $green-300;
}

.iconCircle.info {
  background-color: $amber-300;
}

.iconCircle.decline {
  background-color: $red-300;
}
