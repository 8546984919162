@import '../../../../../static/assets/css/variables';

//This section below is because there are conflicting styles for @idm/authentication-flows
// specifically in inputs (validated and unvalidated).

// TODO:remove conflicts. the components in @idm/authentication-flows should be self styled.
// TODO: change all identity-ux-components to use ux prefix
.idm-contact-info-page .idm-modal-container {
  .input-wrap {
    position: unset;
    max-width: unset;
  }

  .input {
    margin: unset;

    input {
      margin: unset;
    }
  }

  .idm-input-error {
    padding-top: 0;
  }

  .ant-select-selection--single {
    height: inherit;
    position: unset;
  }
}
