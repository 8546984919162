@import '../../../static/assets/css/variables';

.switch-client {
  header {
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;

    .ux-page-header {
      padding-left: 0;
    }
  }

  main {
    .educational-message-text {
      text-align: left;
    }
  }

  footer {
    display: flex;
    justify-content: flex-end;

    div {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    button {
      margin-right: 3%;
    }
  }
}
