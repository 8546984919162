@import '../../../static/assets/css/variables';

.decline-confirmation {
  margin: 0 auto;
  width: 100%;
  max-width: 480px;
  border-radius: $border-radius-default;
  background-color: $white;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
  z-index: 1;
  padding: $size-base*8 $size-base*5;
  font-size: $font-size-base;

  .decline-header {
    display: flex;
    justify-content: center;
    padding-bottom: $size-base*8;

    .decline-icon {
      position: relative;

      .icon-wrapper {
        position: absolute;
        top: 75%;
        transform: translate(-50%, 0);
      }
    }
  }

  .headline {
    font-size: $font-size-lg;
    line-height: $line-height-lg;
    font-weight: $font-weight-medium;
  }

  .description {
    padding: $size-base*2 0;
    line-height: $line-height-md;
  }

  .back-link {
    margin: 0;
    font-size: $font-size-base;
    line-height: $line-height-md;
  }
}
