@import '../../../static/assets/css/variables';

.blue-logo {
  height: 48px;
  width: 143px;
  margin: $size-base*3 auto $size-base*8;
  background-image: url('../../../static/assets/images/Logo@2x.png');
  background-size: 100% 100%;
}

.white-logo {
  margin: $size-base*8;
  height: 47px;
  width: 140px;
  background-image: url('../../../static/assets/images/WhiteLogo@2x.png');
  background-size: 100% 100%;
}
