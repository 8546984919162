.buttonContainer {
  padding: 0 10px;
  display: flex;
}

.buttonContainer:last-child {
  padding-right: 0;
}

.buttonRow {
  display: flex;
  justify-content: center;
  width: 100%;
}

.title {
  padding-left: 0 !important; //this is a problem ui-components has a specificity issue
  justify-content: center;
  display: flex;
}

.form {
  padding-top: 20px;
}
