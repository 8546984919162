@import '../../../../static/assets/css/variables';

.resetMessage {
  margin: $size-base*2 auto $size-base*5;
  text-align: center;
}

.passwordInput {
  padding-left: $size-base;
}

.header2 {
  text-align: center;
}
