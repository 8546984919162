.messageContainer {
  padding-left: 64px;
}

p.message {
  padding-left: 0;
}

h2.title {
  padding-left: 16px;
}
