@import '../../../static/assets/css/variables';

.email-verify-notification {
  .update-email-container {
    .input-wrapper {
      padding-top: 0;
    }

    .idm-input-error {
      padding-top: 0;
    }

    .input { //TODO: fix conflict
      margin: 0;
    }
  }

  .resend-email-notification-container {
    .divider {
      border-bottom: 1px solid $grey-200;
      padding-bottom: 10 * $size-base;
    }

    .resend-verification-link {
      line-height: $line-height-base;
      font-size: $font-size-base;
      color: $blue-400;

      &:hover {
        cursor: pointer;
        color: $blue-500;
      }
    }
  }

  .button-row {
    display: flex;
    justify-content: flex-end;
    padding: $size-base;

    button {
      margin-left: 2*$size-base;
    }
  }

  .paragraph_center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}
