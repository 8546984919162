
.images {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.mobileDisplay {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 10px;
}

.desktopLink {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.linkImage {
  padding: 20px 0 10px;
}

.appImage {
  max-height: 25vh;
  height: 160px;
}

.appStoreImage {
  max-height: 15vh;
  height: 80px;
}

.logo {
  display: flex;
  justify-content: center;
  padding: 0 0 20px;
}

.logoImage {
  height: 30px;
}
