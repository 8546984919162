@import '../../../../static/assets/css/variables';

.idm-input-error {
  color: $red-400;
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
  overflow: hidden;
  transition: all 0s linear 0.2s;
  padding-top: $size-base/2;

  &.hide {
    height: 0;
  }

  svg {
    margin: -$size-base/4 $size-base/2 0 0;
  }
}
