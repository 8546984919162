@import '../../../../../static/assets/css/variables';

.button {
  padding: $size-base $size-base*2;
  line-height: $line-height-base;
  font-size: $font-size-base;
  font-weight: $font-weight-medium;
  text-align: center;
  color: $white;
  border: none;
  border-radius: $border-radius-default;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  transition: background-color 0.1s ease-out;
}

.button:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.button.large {
  font-size: $font-size-md;
  line-height: $line-height-lg;
}

.primary {
  background-color: $blue-400;
  color: $white;
}

.primary:hover {
  background-color: $blue-500;
}

.primary:focus {
  box-shadow: inset 0 0 0 1px $blue-400, inset 0 0 0 2px $white;
}

.primary:disabled {
  opacity: 1;
  background-color: $blue-100;
}

.secondary {
  background-color: $button-color-secondary;
  color: $blue-500;
  border: none;
  box-shadow: inset 0 0 0 1px $blue-100;
}

.secondary:hover {
  background-color: #D7E5FF;
}

.secondary:focus {
  box-shadow: inset 0 0 0 1px $blue-400, inset 0 0 0 2px $white;
}

.link {
  background-color: transparent;
  color: $blue-400;
  border: none;
}

.link:hover {
  color: $blue-500;
}
