@import '../../../static/assets/css/variables';

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: $size-base*3;

  a.footer-link {
    text-decoration: none;
    color: $grey-500;
    font-size: $font-size-base;
    margin: 0 21px;

    &:hover {
      color: $grey-700;
    }
  }
}
