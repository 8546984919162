@import 'modules/fonts';
@import 'keyframes';
@import 'normalize';
@import 'variables';

$header-bottom-margin: $size-base*3;

*,
*:before,
*:after {
  font: inherit;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  font-weight: $font-weight-normal;
  color: $grey-500;
}
// flex container needed to guard against:
// https://github.com/philipwalton/flexbugs#flexbug-3

html,
body,
#identity-ui-client-react-root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ui-page-wrapper {
  height: 100%;
}

.avatar {
  height: 96px;
  width: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 100px;

  &.initials {
    background-color: $green-200;
    color: $white;
    font-size: 24px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
    text-shadow: 0 0 6px rgba(0, 0, 0, 0.2);

    &.rectangular {
      border-radius: 4px;
    }

    &.company {
      background-color: $blue2-300;
    }
  }

  &.unknown {
    background-color: $grey-50;
    border: 1px solid $grey-200;

    &:after {
      content: '?';
      color: $grey-400;
      font-size: 36px;
    }
  }
}

h1,
h2 {
  margin: 0 0 $header-bottom-margin;
  color: $grey-700;
  font-weight: $font-weight-bold;
}

h1 {
  font-size: $font-size-xxl;
  line-height: $size-base*4;
}

h2 {
  font-size: $font-size-xl;
  line-height: $size-base*3;
}

p {
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  line-height: $size-base*3;
  color: $font-color-light;
}

a {
  color: $blue-400;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    color: $blue-500;
  }
}

.input label,
.dropdown label {
  color: $font-color-medium;
}

.idm-modal-container {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  position: absolute;
  background-color: rgba(55, 55, 55, 0.6);
}
