@import '../../../../../static/assets/css/variables';

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2*$size-base;
}

.buttonContainer > * {
  margin-left: $size-base;
  margin-right: $size-base;
}
