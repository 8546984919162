@import '../../../../static/assets/css/variables';

p.displayMessage {
  margin: $size-base*2 auto $size-base*3;
  padding: $size-base 0;
}

.header2 {
  text-align: center;
}
