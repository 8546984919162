@import '../../../../static/assets/css/variables';

.password-input-container {
  position: relative;
}

.password-visibility-toggle {
  font-size: $font-size-sm;
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  color: $grey-600;

  &:hover {
    color: $grey-700;
  }

  .input-large & {
    bottom: 14px;
  }
}
