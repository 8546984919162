@import '../../../static/assets/css/variables';

// $size-base is 8px at the moment.
$pixel0: 0;
$pixel4: $size-base / 2;
$pixel12: $pixel4 * 3;
$pixel16: $pixel4 * 4;
$pixel32: $pixel4 * 8;

.pre-login-mfa {
  display: flex;
  flex-direction: column;
  align-items: center;

  .button-container {
    .button-input {
      font-size: $font-size-md;
      line-height: $line-height-lg;
    }

    margin-top: 15px;
    display: flex;
    justify-content: center;
  }

  .auth-choose-auth-method .auth-enter-code {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .auth-choose-auth-method {
    padding: $pixel0;
    width: auto;
    background-color: transparent;
    box-shadow: none;
    border-radius: $pixel0;

    main {
      max-height: initial;
    }

    header {
      text-align: center;

      .sub-header {
        padding: $pixel16 $pixel0 $pixel32;
      }

      h1 {
        padding: $pixel0;
      }
    }

    main {
      .auth-method-card {
        padding: ($pixel12 + 1) $pixel32;
      }

      .auth-method-card.selected,
      .auth-method-card:hover {
        padding: ($pixel12) ($pixel32 - 1);
      }
    }
  }

  .auth-enter-code {
    padding: $pixel0;
    width: auto;
    background-color: transparent;
    box-shadow: none;
    border-radius: none;
    justify-content: center;
    align-items: center;

    header.modal-header {
      text-align: center;
      display: initial;
      align-items: initial;
      flex-direction: initial;

      .sub-header {
        padding: $pixel16 $pixel0 ($pixel32 + 1);
      }

      h1.ux-page-header {
        padding-left: initial;
        padding-top: initial;
        justify-content: center;
        display: flex;
      }

      p.ux-standard-paragraphs {
        padding-left: initial;
        padding-bottom: initial;
      }
    }

    main {
      text-align: center;
      align-items: initial;
      flex-direction: column;
      padding-bottom: $pixel0;
      display: flex;
      justify-content: center;

      .code-input-container {
        padding-top: $pixel0;
        padding-bottom: $pixel0;
        align-items: center;

        .input {
          margin-top: ($pixel32 - 3);

          .idm-input {
            input {
              margin-top: 0;
            }

            display: flex;
            justify-content: center;
            margin-bottom: 45px;
          }
        }
      }
    }

    footer {
      margin-top: $pixel4 * 8;
      display: flex;
      flex-direction: column;
      justify-content: center !important;
    }
  }

  @media all and (max-width: $break-small) {
    .auth-enter-code {
      main {
        button[data-meta-id='choose-method-responsive'] {
          display: block;
          margin-top: $pixel4 * 5;
        }
      }

      footer {
        button[data-meta-id='choose-method'] {
          visibility: hidden;
          width: 0;
        }
      }
    }
  }
}
