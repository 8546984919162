// Roboto normal

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Light-webfont.eot');
  src: local('Roboto Light'), local('Roboto-Light'), url('../fonts/Roboto-Light-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto-Light-webfont.woff') format('woff'), url('../fonts/Roboto-Light-webfont.ttf') format('truetype'), url('../fonts/Roboto-Light-webfont.svg#robotolight') format('svg');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular-webfont.eot');
  src: local('Roboto'), local('Roboto-Regular'), url('../fonts/Roboto-Regular-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto-Regular-webfont.woff') format('woff'), url('../fonts/Roboto-Regular-webfont.ttf') format('truetype'), url('../fonts/Roboto-Regular-webfont.svg#robotoregular') format('svg');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Medium-webfont.eot');
  src: local('Roboto Medium'), local('Roboto-Medium'), url('../fonts/Roboto-Medium-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto-Medium-webfont.woff') format('woff'), url('../fonts/Roboto-Medium-webfont.ttf') format('truetype'), url('../fonts/Roboto-Medium-webfont.svg#robotomedium') format('svg');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Bold-webfont.eot');
  src: local('Roboto Bold'), local('Roboto-Bold'), url('../fonts/Roboto-Bold-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto-Bold-webfont.woff') format('woff'), url('../fonts/Roboto-Bold-webfont.ttf') format('truetype'), url('../fonts/Roboto-Bold-webfont.svg#robotobold') format('svg');
  font-style: normal;
  font-weight: 700;
}

// Roboto italics

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-LightItalic-webfont.eot');
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'), url('../fonts/Roboto-LightItalic-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto-LightItalic-webfont.woff') format('woff'), url('../fonts/Roboto-LightItalic-webfont.ttf') format('truetype'), url('../fonts/Roboto-LightItalic-webfont.svg#robotolightitalic') format('svg');
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Italic-webfont.eot');
  src: local('Roboto Italic'), local('Roboto-Italic'), url('../fonts/Roboto-Italic-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto-Italic-webfont.woff') format('woff'), url('../fonts/Roboto-Italic-webfont.ttf') format('truetype'), url('../fonts/Roboto-Italic-webfont.svg#robotoitalic') format('svg');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-MediumItalic-webfont.eot');
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'), url('../fonts/Roboto-MediumItalic-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto-MediumItalic-webfont.woff') format('woff'), url('../fonts/Roboto-MediumItalic-webfont.ttf') format('truetype'), url('../fonts/Roboto-MediumItalic-webfont.svg#robotomediumitalic') format('svg');
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-BoldItalic-webfont.eot');
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url('../fonts/Roboto-BoldItalic-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto-BoldItalic-webfont.woff') format('woff'), url('../fonts/Roboto-BoldItalic-webfont.ttf') format('truetype'), url('../fonts/Roboto-BoldItalic-webfont.svg#robotobolditalic') format('svg');
  font-style: italic;
  font-weight: 700;
}

// Font awesome

@font-face {
  font-family: 'adp-font-awesome';
  src: url('../fonts/adp-font-awesome.eot?6s46cn');
  src: url('../fonts/adp-font-awesome.eot?6s46cn#iefix') format('embedded-opentype'), url('../fonts/adp-font-awesome.ttf?6s46cn') format('truetype'), url('../fonts/adp-font-awesome.woff?6s46cn') format('woff'), url('../fonts/adp-font-awesome.svg?6s46cn#adp-font-awesome') format('svg');
  font-weight: normal;
  font-style: normal;
}

// Font awesome
$fa-font-path: '../../../../node_modules/font-awesome/fonts';
