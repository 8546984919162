@import '../../../static/assets/css/variables';

.idm-card {
  border-radius: $border-radius-default;
  margin-bottom: $size-base;
  padding: $size-base*2;
  display: flex;
  align-items: center;
  border: $grey-200 1px solid;

  &.clickable {
    cursor: pointer;
  }

  .left {
    display: flex;
    flex-grow: 1;
    align-items: center;
  }

  .clienttypepill {
    display: flex;
    flex-grow: 20;
    flex-shrink: 0;
    margin-left: $size-base;
  }

  .right {
    display: flex;
    flex-grow: 0;
  }

  .icon {
    margin-right: $size-base*2;

    &.avatar {
      height: 56px;
      width: 56px;
    }
  }

  .main {
    font-size: $font-size-md;
    color: $grey-700;
    line-height: $line-height-md;
    max-width: 312px;
  }

  .sub {
    font-size: $font-size-sm;
    color: $grey-500;
  }

  .action {
    font-size: $font-size-base;
    color: $blue-400;
    cursor: pointer;
    max-width: 264px;

    &.input {
      margin: 0;
    }
  }

  &.active {
    background-color: $blue-50;
    border: 1px solid $blue-50;

    .action {
      color: $grey-400;
      cursor: default;
    }
  }

  &.suspended {
    background-color: $grey-50;
    border: 1px solid $grey-200;

    .icon {
      opacity: 0.4;
    }

    .main {
      color: $grey-400;
    }

    .action {
      color: $grey-400;
      cursor: default;
    }
  }
}
