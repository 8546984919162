@import '~@lifion/ds-foundations-latest/dist/2.0/web-platform/web-tokens';
@import '../../../static/assets/css/variables';

.account-settings {
  max-width: 800px;
  min-height: 800px;
  padding-top: $size-base*8;
  border-radius: $border-radius-default;
  background-color: $white;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
  z-index: 1;

  h1 {
    text-align: center;
    padding-bottom: $size-base;
    margin: 0;
    color: $ds-u-typography-header-l-color;
    font-size: $ds-u-typography-header-l-size;
    line-height: $ds-u-typography-header-l-line-height;
    font-weight: $ds-u-typography-header-l-weight;
  }

  .header-description {
    padding: 12px 140px 8px;
    color: $ds-u-text-color-neutral-light;
    font-size: $ds-u-typography-paragraph-s-size;
    font-weight: calc($ds-u-typography-paragraph-s-weight - 50);
    line-height: $ds-u-typography-paragraph-s-line-height;
    text-align: center;
  }

  .tabs {
    border-bottom: 1px $ds-c-column-border-color solid;
    display: flex;
    justify-content: space-evenly;
    margin-left: $size-base*10;
    margin-right: $size-base*10;
    padding-top: 24px;
  }

  .tabs-horizontal {
    margin-bottom: 0;

    .tab {
      margin-bottom: -14px;
      padding-bottom: 6px;
    }
  }

  .tabs-drop-down {
    display: none;
    margin: 0 auto -1px;
    width: fit-content;

    .ant-select-selection {
      border: none;
      border-radius: 0;
      border-bottom: 2px solid $blue-400;
      width: fit-content;

      .ant-select-selection-selected-value {
        color: $blue-400;
        font-size: $font-size-lg;
      }

      .ant-select-arrow {
        color: $blue-400;

        &:before {
          font-size: $font-size-lg;
          content: '\F0dd';
        }
      }
    }
  }

  .tab-content {
    padding: 10px $size-base*10 $size-base*5 $size-base*10;

    hr {
      margin-top: $size-base*5;
      border: none;
      height: 1px;
      background-color: $grey-200;
    }
  }

  .input-description {
    color: $grey-500;
    font-size: $font-size-sm;
    line-height: 18px;
    margin-top: 7px;
  }

  .idm-button-input {
    float: left;
  }

  .input-wrap {
    position: relative;
    max-width: 432px;
  }

  .validation-box {
    max-width: 432px;
  }

  .dark-line {
    width: 100%;
    border: 1px solid #B2ABA5;
    margin: 24px 24px 0 0;
    height: 1px;
  }

  .action-container {
    float: right;
    width: 100%;
  }

  @media all and (max-width: $break-small) {
    padding: $size-base*10 0 0;
    border-radius: 0;

    .header-description {
      display: none;
    }

    .tabs-horizontal {
      display: none;
    }

    .tabs-drop-down {
      display: block;
    }

    .idm-button-input,
    .idm-button-input input {
      width: 100%;
    }

    .tab-content {
      padding: $size-base*5 20px;
    }
  }
}

.idm-modal.ant-modal {
  .ant-modal-content {
    .ant-modal-close {
      display: none;
    }
  }
}

.idm-dropdown-menu .idm-dropdown-menu-item span {
  font-size: 13px !important;
  font-family: sans-serif !important;
  font-weight: 500 !important;
  color: #262321BF !important;
}
