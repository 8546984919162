@import '../../../static/assets/css/variables';

.legal-agreements {
  .agreement-card {
    padding: $size-base * 3;

    .main {
      font-weight: $font-weight-bold;
      font-size: $font-size-xl;
      line-height: $line-height-lg;
      margin-bottom: $size-base / 2;
    }

    .sub {
      font-size: $font-size-base;
    }
  }

  .agree-button {
    margin: 0;
  }
}
