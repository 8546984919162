@import '../../../../static/assets/css/variables';

.idm-switch-client-tab {
  .only-client-info {
    font-style: italic;
    font-size: $font-size-base;
    text-align: center;
    margin-top: $size-base*3;
  }

  .current-associates {
    margin-bottom: $size-base*5;
  }

  .action-button {
    display: flex;
    justify-content: right;
  }
}
