@import './variables';

%content-container {
  min-height: 100%;
  padding-bottom: $size-base * 4;
}

%white-paper {
  background-color: $white;
  padding: $size-base * 3;
  border-radius: $border-radius-forms;
  box-shadow: $box-shadow-default;
}
