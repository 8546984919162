@import '../../../../static/assets/css/variables';

.checkbox-input {
  position: relative;
  display: flex;
  align-items: center;

  & > .input {
    margin: 10px $size-base*2 10px 0;
  }

  label {
    display: inline-block;
    cursor: pointer;
    margin: 9px 0;
    padding: 0;
    height: 16px;
    width: 16px;
    border-radius: $border-radius-small;
    background-color: $white;
    border: 1px solid $grey-500;

    &:hover {
      border: 1px solid $grey-700;
    }

    svg {
      display: block;
      color: $white;
      fill: $white;
      font-size: $font-size-base;
      line-height: 14px;
      visibility: hidden;
    }
  }

  input[type='checkbox'] {
    visibility: hidden;
    position: absolute;

    &:checked + label {
      background-color: $blue-400;
      border-color: $blue-400;

      svg {
        visibility: visible;
      }
    }
  }

  .checkbox-label {
    padding-left: $size-base*2;
    width: calc(100% - 32px);
    float: right;
    font-size: $font-size-sm;
    line-height: 18px;
  }

  &:disabled {
    opacity: 0.5;
  }

  margin-top: $size-base*3;
}
