@import '../../../../../../../../static/assets/css/variables';

.deleteIcon {
  padding-left: 10px;
  cursor: pointer;
  color: $blue-400;
}

.actionContainer {
  display: flex;
  align-items: center;
}
