@import '../../../static/assets/css/variables';

// fixes so that the contact info can fit
@media (min-width: $break-large + 1) {
  .ui-page-wrapper > main[data-route='/auth/review-contact-info'] {
    div:first-of-type {
      // background
      background-position-x: -10vw;
    }

    div:last-of-type > section {
      // mainContent
      width: 45vw;
    }
  }
}

// layout stuff
.review-contact-info {
  header {
    text-align: center;

    .ux-page-header {
      padding-left: 0;
    }

    .ux-standard-paragraphs {
      padding-left: 0;

      span {
        font-size: $font-size-xs;
      }
    }
  }

  main {
    .method-listing {
      padding-bottom: 10px;

      .authentication-method-display span {
        font-size: $font-size-md;
      }
    }
  }

  footer {
    display: flex;
    justify-content: center;
  }
}
