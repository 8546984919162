@import '../../../../static/assets/css/variables';

.associate-confirmation {
  .identity {
    margin-top: 40px;
    display: flex;

    .avatar {
      width: 64px;
      height: 64px;
    }

    .details {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      margin-left: $size-base*2;
      margin-right: $size-base*3;
      color: $grey-700;
      font-size: $font-size-lg;

      .primary-text {
        line-height: $line-height-lg;

        .name {
          font-weight: $font-weight-bold;
        }
      }

      .secondary-text {
        color: $grey-500;
        font-size: $font-size-sm;
        line-height: $line-height-md;
      }
    }
  }
}
