@import '../../../static/assets/css/variables';

.input {
  input {
    height: 32px;
    width: 100%;
    border: 2px solid $border-color-default;
    border-radius: $border-radius-forms;
    background-color: $white;
    padding: 0 10px;
    outline: none;
    line-height: $line-height-base;
    transition: border-color 0.2s ease-out;
    font-size: $font-size-sm;
    margin-top: $size-base/2;
    z-index: 1;

    @media all and (max-width: $break-small) {
      height: 40px;
    }

    &:hover {
      border-color: $border-color-hover;
    }

    &:focus {
      border-color: $blue-400;
    }
  }

  label {
    height: 16px;
    font-size: $font-size-base;
    line-height: $line-height-base;
    margin-bottom: $size-base;
  }

  &.input-large {
    input {
      height: 40px;
    }

    label {
      font-size: $font-size-base;
    }
  }
}

.input-wrap {
  position: relative;
}

.validated-input {
  &.invalid {
    .blurred {
      input {
        border-radius: $border-radius-default;
        border-color: $red-300;
        transition: all 0s linear 0.2s;
      }
    }
  }

  .validation-box {
    margin-top: $size-base*3;
    width: 100%;
    max-width: 560px;
    border-radius: $border-radius-default;
    background-color: $grey-50;
    padding: 20px;
    font-size: $font-size-sm;
    color: $grey-600;

    span.title {
      height: 15px;
      line-height: 15px;
      margin-bottom: 10px;
    }

    .table {
      margin-top: 10px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .cell {
        display: flex;
        min-width: 50%;
        padding-bottom: 10px;
        line-height: $line-height-md;

        &:last-child {
          padding-right: 0;
        }

        div:first-child {
          width: 14px;
          height: 14px;

          svg {
            fill: green;
            margin-top: -3px;
          }
        }

        span:last-child {
          margin-left: 10px;
        }
      }
    }
  }
}
