
.message {
  display: flex;
  align-items: center;
  background-color: white;
  border-color: rgb(233, 233, 233);
}

.infoIcon {
  width: 80px;
  height: 80px;
  padding-right: 16px;
  color: #2A70E8;
}
