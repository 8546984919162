@import '~@lifion/ds-foundations-latest/dist/2.0/web-platform/web-tokens';

.change-pw-container > h2 {
  font-size: $ds-u-typography-header-m-size !important;
  font-weight: $ds-u-typography-header-m-weight !important;
  line-height: $ds-u-typography-header-m-line-height !important;
  letter-spacing: $ds-u-typography-header-s-letter-spacing !important;
  color: $ds-u-typography-header-m-color !important;
  padding-bottom: 12px !important;
}

.header2 {
  text-align: center;
}
