@import '../../../static/assets/css/variables';

.ui-choose-products-page {
  display: flex;
  justify-content: center;
  width: 100%;
}

.ui-choose-products-page .content {
  max-width: 800px;
  min-width: 300px;
  min-height: 85vh;
  width: 100%;
  border-radius: $border-radius-default;
  background-color: $white;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
  z-index: 1;
}
