@import '../../../static/assets/css/variables';

.login-page {
  .buttons-links-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  .sso-login-link {
    margin-top: 40px;
    margin-right: auto;
    font-weight: 400;
    align-items: left;
    justify-content: left;
    padding-top: 16px;
  }

  .reset-password-link {
    margin-right: auto;
    font-weight: 400;
    align-items: left;
    justify-content: left;
    padding-top: 16px;
  }

  .button-custom-container {
    padding-top: 10px;
  }

  .next-button,
  .log-in-button {
    min-width: 100px;
    border-radius: 8px;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .registration-email-container {
    padding-bottom: 30px;
  }

  .input {
    input {
      border-radius: 8px;
    }
  }

  .send-registration-email {
    text-align: center;
    margin: $size-base auto;
  }

  .input-large .password-visibility-toggle {
    bottom: 10px;
    border-radius: 50%;
  }

  .loader {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }
}

.flexLeft {
  display: flex;
  justify-content: left;
}

.infoButton {
  color: currentcolor;
  position: relative;
  border: none;
  background: none;
}

.tooltip {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(10px, -50%);
  display: none;
  background-color: white;
  color: black;
  border: 1px solid lightgrey;
  padding: 5px;
  border-radius: 5px;
  z-index: 100;
  width: 300px;
  font-size: 12px;
  line-height: 16px;
  white-space: normal;
}

.adplogo {
  display: flex;
  height: 60px;
  justify-content: center;
}

.adplogoImage {
  background-image: url('../../../static/assets/images/unauthenticated/ADP_logo.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 125px;
  height: 60px;
}

.login-page-welcome-title {
  text-align: center;
  font-size: 1.4rem;
  font-family: 'Proxima Nova', sans-serif;
  font-weight: 730;
  margin-top: 5vh;
  margin-bottom: 15px;
}

.link-text {
  text-align: left;
  font-size: 14px;
  font-family: 'Proxima Nova', sans-serif;
  font-weight: 550;
  color: #324FA5;
  margin-bottom: 1px;
}

.label {
  display: inline-block;
  margin-bottom: 10px;
}

.hide-password {
  display: none;
}

.dark-line {
  width: 100%;
  border: 1px solid #B2ABA5;
  margin: 24px 24px 0 0;
  height: 1px;
}

.links-section,
.buttons-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.input-large {
  label {
    font-weight: 550;
    color: #262321;
  }
}

@media all and (min-width: $break-large + 1) {
  .login-input-large {
    width: 60%;
  }

  .login-input-wrap {
    position: relative;
    display: flex !important;
    justify-content: center;
  }
}

@media all and (max-width: $break-large) {
  .login-page {
    .buttons-links-container {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    }

    .sso-login-link,
    .reset-password-link {
      align-items: center;
      justify-content: center;
    }

    .links-section,
    .buttons-section {
      align-items: center;
    }
  }
}
