@import '../../../../static/assets/css/variables';

.personal-info {
  .greeting-row {
    display: flex;
    margin-bottom: $size-base*5;

    .company-logo {
      flex-shrink: 0;
      margin-right: $size-base*2;
      height: 104px;
      width: 104px;
      border-radius: $border-radius-large;
    }

    h1 {
      margin: 0;
    }
  }

  .account-exists-link {
    color: $grey-500;
    font-size: $font-size-sm;
    margin-bottom: $size-base*5;

    a {
      margin: 0;
    }
  }

  p {
    margin: $size-base*5 auto $size-base*2;
  }

  .input-large.idm-email-wrapper {
    .input-error-message {
      .error-message {
        padding-left: $size-base;

        .click-here-link {
          padding: 0;
          font-size: $size-base + $size-base/2 + $size-base/$size-base;
        }
      }
    }
  }
}
