@import '../../../static/assets/css/variables';

.account-registration {
  .greeting-row {
    display: flex;
    margin-bottom: 40px;

    .company-logo {
      flex-shrink: 0;
      margin-right: $size-base*2;
      height: 104px;
      width: 104px;
      border-radius: $border-radius-large;
    }

    h1 {
      margin: 0;
    }
  }

  .option-card {
    margin: 16px 0;
    padding: $size-base * 3;

    .main {
      font-weight: $font-weight-medium;
      font-size: $font-size-xl;
      line-height: $line-height-lg;
      margin-bottom: $size-base / 2;
    }

    .sub {
      font-size: $font-size-base;
      line-height: 20px;
    }

    svg {
      fill: $grey-200;
    }
  }

  .text-center {
    text-align: center;
    line-height: 24px;
  }

  .datepicker-wrapper {
    padding-top: 40px;
  }
}
