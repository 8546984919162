.badLinkReasons {
  margin-left: 40px;
}

.badLinkReasons li {
  padding-top: 16px;
}

.errorMessage {
  padding-top: 32px;
}

.accessLink {
  padding-left: 0;
}
