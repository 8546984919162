@import '../../../static/assets/css/variables';

.account-linking {
  margin: 0 auto;
  width: 100%;
  max-width: 480px;
  border-radius: $border-radius-default;
  background-color: $white;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
  z-index: 1;
  padding: $size-base*8 $size-base*5;
  font-size: $font-size-base;

  & > * {
    text-align: center;
  }

  .greeting-row {
    color: $grey-500;
    margin-bottom: $size-base;
    line-height: $line-height-base;
  }

  h3 {
    font-size: $font-size-lg;
    font-weight: $font-weight-semibold;
    line-height: $size-base*3;
  }

  .images-row {
    display: flex;
    justify-content: center;
    margin: $size-base*5 auto;
    color: $grey-500;
    line-height: $line-height-base;

    .avatar {
      margin: 0 auto $size-base;
    }

    i {
      color: $grey-200;
      font-size: 28px;
      margin: 33px $size-base*5 57px;
    }

    .display-text {
      width: 96px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .instructions-row {
    line-height: $line-height-md;
  }

  .connect-button {
    margin: $size-base*5 auto;

    input {
      margin-top: 0;
      float: inherit;
    }
  }

  .actions-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    > span {
      margin: $size-base*3 20px 0;
      white-space: nowrap;
      text-decoration: none;
      color: $blue-400;
      text-overflow: ellipsis;
      overflow: hidden;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
