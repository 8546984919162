@import '../../../static/assets/css/variables';

.avatar {
  width: 100%;
  height: 100%;
  max-height: 96px;
  max-width: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 100px;
}

.initials {
  background-color: $green-200;
  color: $white;
  font-size: 24px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
}

.rectangular {
  border-radius: 4px;
}

.company {
  background-color: $blue2-300;
}

.unknown {
  background-color: $grey-50;
  border: 1px solid $grey-200;
}

.unknown:after {
  content: '?';
  color: $grey-400;
  font-size: 36px;
}
