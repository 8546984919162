@import '~/src/lib/static/assets/css/ux_2.0';

.authenticatedPage {
  min-height: 100%;
  padding: 84px 0 57px;
  background: $bds-e-skeleton-loading-background-color;
  //background: radial-gradient(150% 300% ellipse at -30% 70%, #5D68FF, #4583F8 57%, #3F77F3 58%, #4D87FE 58%, #4D87FE 82%, #3181F3 82%, #419BFD 83%, #3DADFF 100%);
  display: flex;
  justify-content: center;
}

.error {
  max-width: 512px;
}
